import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { OrganizerTeamState } from '@ebursa/organizer/stores/states';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { OrganizerTeamStore } from '@ebursa/organizer/stores/organizer-team.store';
import { OrganizerTeam } from '@ebursa/organizer/models/organizer-team';

@Injectable({ providedIn: 'root' })
export class OrganizerTeamRepository extends Repository<OrganizerTeamState> {
    public constructor(store: OrganizerTeamStore) {
        super(store);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public selectOrganizerTeams$(): Observable<Collection<OrganizerTeam>> {
        return this.select((state: OrganizerTeamState) => state.data.organizerTeams);
    }

    public selectOrganizerTeam$(): Observable<OrganizerTeam> {
        return this.select((state: OrganizerTeamState) => state.data.organizerTeam);
    }
}
